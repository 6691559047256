<template>
  <div class="AccountUpdatedView">
    <p>Your account has been updated. Please wait while we forward you back to your dashboard.</p>
  </div>
</template>

<script>
export default {
    async mounted() {
        await this.$store.dispatch('moduleAuth/updateAuthToken');
        this.$router.push({ name: 'DashboardHomeView' });
    }
};
</script>

<style lang="scss" scoped>
.AccountUpdatedView {
    padding: 1em;
}
</style>
